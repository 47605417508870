<template>
    <div>
        <div v-if="authUserPermission['user-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col-3">
                                <h3 class="mb-0">{{ tt('account_list') }}</h3>
                            </div>
                            <div class="col-5">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search_account')" v-on:keyup="filter"/>
                                </div>
                            </div>
                            <div class="col text-right">
                                <router-link to="/manage/manage-account-add" class="btn btn-sm btn-dark" v-if="authUserPermission['user-create']">{{ tt('add_new') }}</router-link>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                            <el-table-column :label="tt('username')" :prop="tt('username')" min-width="200px" sortable>
                                <template v-slot="{row}">
                                    <div class="media align-items-center">
                                        <a class="avatar avatar-sm rounded-circle mr-3">
                                            <img :src="loadAvatar(row.image)" v-if="row.image">
                                            <img src="" v-else>
                                        </a>
                                        <div class="media-body">
                                        <span class="mb-0 text-sm">{{row.username}}</span>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('name')" :prop="tt('name')" min-width="250px" sortable>
                                <template v-slot="{row}">
                                    {{row.name}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('role')" :prop="tt('role')" min-width="170px" sortable>
                                <template v-slot="{row}">
                                    <span v-for="v in row.roles">
                                        <label class="badge badge-success">{{v.name}}</label>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('cat_type')" :prop="tt('cat_type')" min-width="150px" sortable>
                                <template v-slot="{row}">
                                    <span v-for="v in row.rel_user_cat_type">
                                        <label class="badge badge-danger">{{v.catalog_type_code}}</label>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('email')" :prop="tt('email')" min-width="190px" sortable>
                                <template v-slot="{row}">
                                    {{row.email}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('company')" :prop="tt('company')" min-width="190px" sortable>
                                <template v-slot="{row}">
                                    {{row.company_code}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('status')" :prop="tt('status')" min-width="170px" sortable>
                                <template v-slot="{row}">
                                    <span class="badge badge-dot mr-4">
                                        <i :class="row.status == 1 ? 'bg-success' : 'bg-danger'"></i> 
                                        <span v-if="row.status == 1">Active</span>
                                        <span v-else>Non Active</span>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="action" width="100">
                                <template v-slot="{row}">
                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['user-update'] || authUserPermission['user-delete'] || authUserPermission['user-update-status']">
                                        <span class="btn btn-sm btn-icon-only text-light">
                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                            <el-dropdown-item :command="{action:'edit', data:row.id}" v-if="row.created_by == 'SSO' && authUserPermission['user-update'] ">{{ tt('edit_account') }}</el-dropdown-item>
                                            <router-link :to="{path: '/manage/manage-account-edit/', query: {data:row.id}}" class="dropdown-item ml-1" tag="button" v-if="row.created_by == 'SMARTCAT' && authUserPermission['user-update']">{{ tt('edit_account') }}</router-link>
                                            <el-dropdown-item :command="{action:'remove', data:row.id}" v-if="authUserPermission['user-delete']">{{ tt('delete_account') }}</el-dropdown-item>                                        
                                            <!-- <el-dropdown-item :command="{action:'changeStatus'}" v-if="authUserPermission['user-update-status']">{{ tt('change_status') }}</el-dropdown-item>                                         -->
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                        <page-loading v-else/>
                    </div>      
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{form.title}}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>

                        <label class="form-control-label">{{ tt('role') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('role')" rules="required">
                            <el-select class="select-danger" v-model="manageAccount.roles" multiple :placeholder="tt('choose_role')">
                                <el-option :value="r.name" :label="r.name" :key="r.id" v-for="r in role"></el-option>
                            </el-select>
                        </base-input>

                        <label class="form-control-label">{{ tt('catalog_type') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('catalog_type')" rules="required">
                            <el-select class="select-danger" v-model="manageAccount.rel_user_cat_type" multiple :placeholder="tt('choose_catalog_type')">
                                <el-option :value="ct.code" :label="ct.code +' - '+ ct.description" :key="ct.id" v-for="ct in catalogType"></el-option>
                            </el-select>
                        </base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>
            <!-- <modal :show.sync="formChangeStatus.show">
                <template slot="header">
                    <h5 class="modal-title">{{ tt('change_status') }}</h5>
                </template>
                <div>
                    <base-input>
                        <el-select class="select-danger" v-model="sapConnection.company" :placeholder="tt('choose_status')">
                            <el-option class="select-danger" value="01" label="Active" key="Active"></el-option>
                            <el-option class="select-danger" value="02" label="Inactive" key="Inactive"></el-option>
                        </el-select>
                    </base-input>
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="formChangeStatus.show = false">{{ tt('close') }}</base-button>
                    <base-button type="primary">{{ tt('save_changes') }}</base-button>
                </template>
            </modal> -->
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import manageAccount from '@/services/manage/manageAccount.service';
    import catalogType from '@/services/master/catalogType.service';
    import role from '@/services/manage/manageRole.service';
    import config from '@/configs/config';

    export default {        
        data() {
            return {           
                onLoad: true,      
                loadTimeout: null,   
                btnSave: {
                    onLoading: false
                },
                form: {
                    add: true,
                    title: "",
                    show: false
                },     
                errorMessage: null,   
                formChangeStatus: {
                    show: false
                }, 
                table: {
                    search: '',                    
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },
                manageAccount: {},
                catalogType: {},
                role: {},
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
            this.getCatalogType()
            this.getRole()
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, manageAccount.get(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data; 
                    context.table.page  = response.data.data.data;   
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                }).onFinish(function() {
                    context.onLoad = false;
                }).call()
            },
            getCatalogType() { 
                let context = this;               
                Api(context, catalogType.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.catalogType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.catalogType = [];
                    }
                }).onFinish(function() {
                    context.onLoad = false;
                }).call()
            },
            getRole() { 
                let context = this;               
                Api(context, role.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.role = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.role = [];
                    }
                }).onFinish(function() {
                    context.onLoad = false;
                }).call()
            },
            filter() {
                let context = this;
                context.onLoad = true;
                this.table.page.current_page = 1;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    case 'changeStatus':
                            this.changeStatus(command.data);
                        break;
                    default:
                        break;
                }
            },
            edit(id) {
                this.errorMessage = null;
                let context = this;               
                Api(context, manageAccount.show(id)).onSuccess(function(response) {
                    context.manageAccount = response.data.data;
                    var roles = [];
                    var rel_user_cat_type = [];
                    response.data.data.roles.forEach(function(item) {
                        roles.push(item.name)
                    })
                    response.data.data.rel_user_cat_type.forEach(function(item) {
                        rel_user_cat_type.push(item.catalog_type_code)
                    })

                    context.manageAccount.roles = roles
                    context.manageAccount.rel_user_cat_type = rel_user_cat_type

                    context.form.add = false;
                    context.form.title = context.tt('update_account');
                    context.form.show = true;                          
                })
                .call()        
            },
            save() {                      
                this.btnSave.onLoading = true;
                let api = null;
                let context = this;

                if (this.form.add) {
                    api = Api(context, manageAccount.create(this.manageAccount));
                } else {{
                    api = Api(context, manageAccount.update(this.manageAccount.id, this.manageAccount));
                }}
                api.onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.form.show = false;
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                })
                .call();
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, manageAccount.delete(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
            changeStatus() {
                this.formChangeStatus.show = true;
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            loadAvatar(image) {
               return config.storageUrl+'images/image_user/'+image;
            }
        }   
    };
</script>
